<template>
  <div class="page">
    <!--<el-carousel indicator-position="outside" :height="casHeight" :interval="5000">-->
      <!--<el-carousel-item v-for="(item,index) in picList" :key="index">-->
        <!--<el-image-->
          <!--:src="item.src"-->
          <!--style="width: 100%; height: 100%"-->
        <!--&gt;</el-image>-->
      <!--</el-carousel-item>-->
    <!--</el-carousel>-->
    <img src="../../assets/images/homeBG.png" class="backgroundPic"/>
    <div class="flexBoxCol">
      <div class="p48-400 mtp50 mbm50">斯点智慧校园平台功能</div>
      <div class="homeIconLine">
        <div class="homeIconBox">
          <!--<img src="../../assets/images/call.png" class="imageSize">-->
          <svg-icon icon-class="call"></svg-icon>
          <p class="p24">亲情通话</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/location.png" class="imageSize">-->
          <svg-icon icon-class="location"></svg-icon>
          <p class="p24">实时定位</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/track.png" class="imageSize">-->
          <svg-icon icon-class="track"></svg-icon>
          <p class="p24">轨迹分析</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/sos.png" class="imageSize">-->
          <svg-icon icon-class="sos"></svg-icon>
          <p class="p24">紧急呼叫</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/disable.png" class="imageSize">-->
          <svg-icon icon-class="disable"></svg-icon>
          <p class="p24">上课禁用</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/cutOff.png" class="imageSize">-->
          <svg-icon icon-class="cutOff"></svg-icon>
          <p class="p24">陌生电话禁止</p>
        </div>
      </div>
      <div class="homeIconLine">
        <div class="homeIconBox">
          <!--<img src="../../assets/images/card.png" class="imageSize">-->
          <svg-icon icon-class="card"></svg-icon>
          <p class="p24">校园一卡通</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/attendance.png" class="imageSize">-->
          <svg-icon icon-class="attendance"></svg-icon>
          <p class="p24">出入校考勤</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/clock.png" class="imageSize">-->
          <svg-icon icon-class="deyu"></svg-icon>
          <p class="p24">德育评价</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/lighting.png" class="imageSize">-->
          <svg-icon icon-class="xiaowu"></svg-icon>
          <p class="p24">校务管理</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/calendar.png" class="imageSize">-->
          <svg-icon icon-class="jiaxiao"></svg-icon>
          <p class="p24">家校沟通</p>
        </div>
        <div class="homeIconBox">
          <!--<img src="../../assets/images/pay.png" class="imageSize">-->
          <svg-icon icon-class="fenxi"></svg-icon>
          <p class="p24">智能分析</p>
        </div>
      </div>
    </div>
    <div class="flexBoxCol">
      <div class="p48-700 mtp50 mbm50">产品简介</div>
      <div class="boxHomeLine">
        <div>
          <div class="p36 boxHomeLineTitle">闸机</div>
          <div class="p24 boxHomeLineText">
            <p>针对不同地点、不同时段、不同人群</p>
            <p>灵活设置面板机及闸机通道对应的通行规则</p>
            <p>通过后台设置考勤规则，实现人员出入管理</p>
            <p>家长端信息数据同步</p>
          </div>
        </div>
        <img src="../../assets/images/homePic1.png" class="homePic"/>
      </div>
      <div class="boxHomeLine backColor">
        <img src="../../assets/images/homePic2.png" class="homePic mrt50"/>
        <div>
          <div class="p36 boxHomeLineTitle">考勤</div>
          <div class="p24 boxHomeLineText">
            <p>无感测温，异常告警</p>
            <p>通行记录推送家长，上下学家长更安心</p>
            <p>应用微信生态，无需另行下载应用软件</p>
          </div>
        </div>
      </div>
      <div class="boxHomeLine">
        <div>
          <div class="p36 boxHomeLineTitle">智能大屏</div>
          <div class="p24 boxHomeLineText">
            <p>学生信息，一目了然</p>
            <p>出入围栏预警，防范学生接近危险场地</p>
            <p>实时定位，让学生安全更有保障</p>
          </div>
        </div>
        <img src="../../assets/images/homePic3.png" class="homePic"/>
      </div>
      <div class="boxHomeLine backColor">
        <img src="../../assets/images/homePic4_0.png" class="homePic mrt50"/>
        <div>
          <div class="p36 boxHomeLineTitle">电子学生证</div>
          <div class="p24 boxHomeLineText">
            <p>Type C充电口</p>
            <p>通讯白名单避免被垃圾电话骚扰</p>
            <p>亲情号码满足家庭电话沟通需求</p>
          </div>
        </div>
      </div>
      <div class="boxHomeLine">
        <div>
          <div class="p36 boxHomeLineTitle">校园消费</div>
          <div class="p24 boxHomeLineText">
            <p>在线充值，刷脸消费</p>
            <p>聚合支付，同步支持刷卡消费</p>
            <p>小程序上可查看消费记录、用餐记录</p>
            <p>系统后台自动保存所有记录</p>
          </div>
        </div>
        <img src="../../assets/images/homePic5.png" class="homePic"/>
      </div>
    </div>
    <div class="flexBoxCol">
      <div class="p48-700 mtp50 mbm50">核心亮点</div>
      <div class="boxHomeLine">
        <div>
          <div class="p36 boxHomeLineTitle">软硬件结合</div>
          <div class="p24 boxHomeLineText">
            <p>以电子学生证为感知入口，采集学生状态信息（位置、</p>
            <p>考勤、告警、通话、消费等），结合云平台,</p>
            <p>实现家校互联，守护学生安全, 便捷学生在校生活，</p>
            <p>为校园安全管理提供数据支撑及决策支持。</p>
          </div>
        </div>
        <img src="../../assets/images/homePic6.png" class="homePic"/>
      </div>
      <div class="boxHomeLine backColor">
        <img src="../../assets/images/homePic7.png" class="homePic mrt50"/>
        <div>
          <div class="p36 boxHomeLineTitle">AI+大数据智能分析</div>
          <div class="p24 boxHomeLineText">
            <p>AI与大数据分析技术相结合，从学生的考勤情况，</p>
            <p>课业成绩、心理测评等维度对孩子进行分析。</p>
            <p>根据分析结果，老师可以快速调整教学方法，因材施教；</p>
            <p>家长则能更全面、更科学了解孩子，进而提高家校共育能力</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flexBoxCol">
      <div class="p48-700 mtp50 mbm50">解决案例</div>
      <div class="schoolBoxLine mbm50">
        <div class="schoolBox">
          <img src="../../assets/images/school1.png" class="schoolImg"/>
          <div class="schoolText">大祥一中</div>
        </div>
        <div class="schoolBox">
          <img src="../../assets/images/school2.png" class="schoolImg"/>
          <div class="schoolText">新渡学校</div>
        </div>
        <div class="schoolBox">
          <img src="../../assets/images/school3.png" class="schoolImg"/>
          <div class="schoolText">城南小学</div>
        </div>
      </div>
      <div class="schoolBoxLine">
        <div class="schoolBox">
          <img src="../../assets/images/school4.png" class="schoolImg"/>
          <div class="schoolText">百春园小学</div>
        </div>
        <div class="schoolBox">
          <img src="../../assets/images/school5.png" class="schoolImg"/>
          <div class="schoolText">滑石小学</div>
        </div>
        <div class="schoolBox">
          <img src="../../assets/images/school6.png" class="schoolImg"/>
          <div class="schoolText">三八亭小学</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      picList: [
        {
          src: require('../../assets/images/home.png')
        }
      ]
    }
  }
}
</script>
<style scoped lang="less">
  .homeIconLine {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
  }
  .homeIconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    width: 150px;
  }
  .imageSize {
    width: 82px;
    height: 82px;
    object-fit: fill;
  }
  .boxHomeLine {
    display: flex;
    align-items: center;
    background: #95CDF7;
    padding: 50px 0;
    width: 100%;
    justify-content: space-around;
  }

  .backColor {
    background: #F2F3F2;
  }
  .boxHomeLineTitle {
    text-align: left;
    margin-bottom: 50px;
  }
  .boxHomeLineText {
    text-align: left;
    letter-spacing: 0.1em;
    line-height: 30px;
  }
  .homePic {
    width: 720px;
    height: 480px;
  }
  .schoolBoxLine {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .schoolBox {
    width: 300px;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .schoolText {
    position: absolute;
    left: 50px;
    bottom: 10px;
    width: 200px;
    height: 50px;
    background: #1A90E8;
    border-radius: 15px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .schoolImg {
    width: 300px;
    height: 300px;
  }
</style>
